@font-face {
  font-family: 'Rota';
  src: url('./fonts/Rota/Rota-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RotaSemiBold';
  src: url('./fonts/Rota/Rota-SemiBold.otf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'RotaBold';
  src: url('./fonts/Rota/Rota-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Rota';
  src: url('./fonts/Rota/Rota-ExtraBold.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Rota';
  src: url('./fonts/Rota/Rota-ExtraBlack.otf') format('truetype');
  font-weight: 1000;
}

* {
  font-family: 'Rota' !important;
}

*::-webkit-scrollbar {
  height: 4px;
  width: 6px;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #b6b8d5;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rota' !important;
  font-weight: 700;
}

p {
  font-family: 'Rota' !important;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: 'Rota' !important;
}

/* .preview-container p { */
/* font-family: sans-serif !important; */
/* font-weight: normal !important; */
/* font-size: 16px; */
/* } */

.preview-container p strong {
  font-weight: 800 !important;
}

#SMS-preview-container p,
#telegram-preview-container p,
#discord-preview-container p,
#alert-history-preview-container p,
#browser-preview-container p {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}

#email-preview-container {
  height: 400px;
  overflow: scroll;
}
#email-preview-container::-webkit-scrollbar {
  display: none;
}

#SMS-preview-container {
  max-width: 500px;
  background-color: #f5f6fb;
  padding: 20px 22px;
  border-radius: 12px;
  line-height: 20px;
}

#telegram-preview-container {
  max-width: 500px;
  background-color: #f5f6fb;
  padding: 20px 22px;
  border-radius: 12px;
  line-height: 20px;
}

#discord-preview-container {
  max-width: 600px;
  background-color: #f5f6fb;
  padding: 20px 22px;
}

#alert-history-preview-container {
  max-width: 500px;
  font-family: sans-serif;
  font-size: 24px;
}

#browser-preview-container {
  max-width: 500px;
  background-color: #e8ebf5;
  border-radius: 12px;
  line-height: 20px;
}

/* TODO: move MuiTabs-root styles to Theme.tsx */
.MuiTabs-root .MuiButtonBase-root {
  color: #80829d;
  font-family: 'Rota', sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-transform: unset;
}
.MuiTabs-root .MuiTabs-indicator {
  background: #6d79ff;
  height: 4px;
}
.MuiTabs-root .MuiButtonBase-root.Mui-selected {
  color: #fff;
}

.MuiTableContainer-root {
  border-radius: 8px !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;
}

.MuiTableCell-root .MuiFormControl-root {
  margin: 0;
}

.svg-color-heather path {
  fill: #80829d;
}
.MuiPickersDay-root.Mui-selected {
  border-radius: 2px !important;
  color: white !important;
  background-color: #5260f0 !important;
}

.MuiPickersDay-root {
  border-radius: 2px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.MuiPickersCalendarHeader-switchViewButton {
  color: transparent !important;
  background-image: url(/src/assets/icn-chevron-down.svg);
  background-repeat: no-repeat;
}

.MuiPickersDay-root.Mui-disabled:not(.Mui-selected) {
  color: #80829d !important;
}

.MuiPickersDay-root:hover {
  border-radius: 2px !important;
  color: white !important;
  background-color: #525ff0ad !important;
}

.MuiDayCalendar-weekDayLabel {
  color: #262949 !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.MuiPickersCalendarHeader-label {
  color: #262949 !important;
  font-weight: 700 !important;
  font-size: 22px !important;
}

.MuiPickersArrowSwitcher-root {
  display: none !important;
}

.MuiPickersMonth-monthButton {
  color: #262949 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.MuiPickersMonth-monthButton.Mui-disabled {
  color: #80829d !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.MuiPickersPopper-paper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.MuiDateCalendar-root {
  height: 285px !important;
}

.svg-color-Midnight path {
  fill: #262949;
}
